import React from 'react';
import './index.css';
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals';
import store from './redux/store'
 import { BrowserRouter } from 'react-router-dom';
import './assets/css/stylethem.css'

 import './assets/css/animate/animate.min.css'
  import './assets/css/owlcarousel/assets/owl.carousel.min.css'
  import './assets/css/bootstrap/bootstrap.min.css'
import DefaultLayout from './components/DefaultLayout/DefaultLayout';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';


 createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  <Provider store={store}>
     <BrowserRouter>
        <DefaultLayout/>
     </BrowserRouter>
     <NotificationContainer />
  </Provider>
</React.StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
