import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPaidStudentfees,
  getStudentDetailsbyGrNo,
  openCcavenuepage,
  //payStudentFee,
} from '../../redux/services/apicall';
import {
  fetchStudentInfoFailure,
  fetchStudentInfoRequest,
  fetchStudentInfoSuccess,
} from '../../redux/action/fetchaction';
import { downloadReceipt, getReceipt } from '../../redux/services/apicall';

const FeesRecipt = () => {
  const [grNo, setGerNo] = useState('');

  const dispatch = useDispatch();
  const { studentInfo } = useSelector((state) => state.feesreducer);

  const studentDetails =
    studentInfo && studentInfo.length > 0 ? studentInfo[0] : null;

  const onGrNoChange = async (e) => {
    setGerNo(e.target.value);
    if (e.target.value && e.target.value.length >= 3) {
      dispatch(fetchStudentInfoRequest());
      try {
        const details = { grno: e.target.value };
        const studentDetails = await getStudentDetailsbyGrNo(details);
        dispatch(fetchStudentInfoSuccess(studentDetails?.data));
        console.log(studentDetails?.data);
      } catch (error) {
        dispatch(fetchStudentInfoFailure(error.message));
      }
    }
  };

  useEffect(() => {
    getStudentFeesdetails();
  }, []);

  const getStudentFeesdetails = async () => {
    const fees = await getPaidStudentfees();
    console.log(fees);
  };

  const handleDownload = async () => {
    try {
      const data = {
        grno: grNo,
      };
      await getReceipt(data)
        .then(function (response) {
          if (response.success) {
            downloadReceipt(grNo);
          }
        })
        .catch(function (e) {
          console.error('Error creating receipt', e);
        });
    } catch (error) {
      console.error('Error creating receipt', error);
    }
  };

  return (
    <div className='container-xxl py-5'>
      <div
        className='text-center wow fadeInUp '
        data-wow-delay='0.1s facilitiybutton'
      >
        <h6 className='section-title bg-white text-center text-primary px-3'>
          Download
        </h6>
        <h1 className='mb-5'>Fees Receipt</h1>
      </div>

      <form method='POST' name='customerData' action=''>
        <div className='row g-3'>
          <div className='col-md-8 mx-auto'>
            <div className='row g-3'>
              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='number'
                    className='form-control'
                    id='number'
                    placeholder='Enter yout GR Number'
                    onChange={(e) => onGrNoChange(e)}
                    value={grNo}
                  />
                  <label htmlFor='text'>GR Number</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='number'
                    className='form-control'
                    id='number'
                    placeholder='Fees Amount'
                    readOnly
                    value={studentDetails ? studentDetails.fees ?? 0 : 0}
                    //onChange={(e) => setFeesAmt(e.target.value)}
                  />
                  <label htmlFor='text'>Fees Amount</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='First Name'
                    readOnly
                    value={studentDetails ? studentDetails.first_name : ''}
                  />
                  <label htmlFor='name'>First Name</label>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Middle Name'
                    readOnly
                    value={studentDetails ? studentDetails.middle_name : ''}
                  />
                  <label htmlFor='text'>Middle Name</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Last Name'
                    readOnly
                    value={studentDetails ? studentDetails.last_name : ''}
                  />
                  <label htmlFor='text'>Last Name</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='number'
                    placeholder='standard'
                    readOnly
                    value={studentDetails ? studentDetails.standard : ''}
                  />
                  <label htmlFor='text'>standard</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='number'
                    placeholder='Division'
                    readOnly
                    value={studentDetails ? studentDetails.division : ''}
                  />
                  <label htmlFor='text'>Division</label>
                </div>
              </div>

              <div className='col-12'>
                <button
                  className='btn btn-primary w-100 py-3'
                  type='button'
                  value='Pay'
                  onClick={handleDownload}
                >
                  Download Fees Receipt
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default FeesRecipt;
