import {
  FITCH_NOTIFICATION_ITEM_REQUEST,
  FITCH_NOTIFICATION_ITEM_SUCCESS,
} from '../constants/constants';

const initialState = {
  notification: [],
  loading: false,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FITCH_NOTIFICATION_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FITCH_NOTIFICATION_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        notification: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
