import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPaidStudentfees,
  getStudentDetailsbyGrNo,
  openCcavenuepage,
  //payStudentFee,
} from '../../redux/services/apicall';
import {
  fetchStudentInfoFailure,
  fetchStudentInfoRequest,
  fetchStudentInfoSuccess,
} from '../../redux/action/fetchaction';

const RegularFees = () => {
  const [grNo, setGerNo] = useState('');
  const dispatch = useDispatch();
  const { studentInfo } = useSelector((state) => state.feesreducer);
  const [studentExist, setStudentExist] = useState(true);
  

  const studentDetails =
    studentInfo && studentInfo.length > 0 ? studentInfo[0] : null;

  // const location = useLocation();
  // const [response, setResponse] = useState(null);

  // useEffect(() => {
  //   const fetchResponse = async () => {
  //     const params = new URLSearchParams(location.search);
  //     const encResp = params.get('encResp');

  //     if (encResp) {
  //       const response = await fetch('http://localhost:8088/payment/callback', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         body: new URLSearchParams({ encResp }),
  //       });

  //       const data = await response.json();
  //       setResponse(data);
  //     }
  //   };

  //   fetchResponse();
  // }, [location.search]);

  const onGrNoChange = async (e) => {
    setGerNo(e.target.value);
    if (e.target.value && e.target.value.length >= 1) {
      dispatch(fetchStudentInfoRequest());
      try {
        const details = { grno: e.target.value };
        const studentDetails = await getStudentDetailsbyGrNo(details);
         dispatch(fetchStudentInfoSuccess(studentDetails?.data));
         if (!studentDetails?.data || studentDetails?.data.length <= 0) {
          setStudentExist(false);         
        } else {
          setStudentExist(true);
         }
        console.log(studentDetails?.data);
      } catch (error) {
        dispatch(fetchStudentInfoFailure(error.message));
      }
    }
  };

  useEffect(() => {
    getStudentFeesdetails();
  }, []);

  const getStudentFeesdetails = async () => {
    const fees = await getPaidStudentfees();
    console.log(fees);
  };

  const onPayClick = async () => {
    console.log('studentDetails', studentDetails);
    const paymentObj = { grNo: studentDetails.gr_no, firstName:studentDetails.first_name, amount: studentDetails.fees };
    const response = await openCcavenuepage(paymentObj);
    console.log('payment response ', response);
    window.location.replace(response);
  };

  return (
    <div className='container-xxl py-5'>
      <div
        className='text-center wow fadeInUp '
        data-wow-delay='0.1s facilitiybutton'
      >
        <h6 className='section-title bg-white text-center text-primary px-3'>
          Pay School
        </h6>
        <h1 className='mb-5'>Fees</h1>
        <h5> Please Enter your GR No to pay the Fees.</h5>

      </div>
      <div className='text-center' style={{ margin: '10px' }}>
        {studentExist ? (
          ''
        ) : grNo ? (
          <span style={{ color: 'red' }}>
            Dear Student your Gr No {grNo} is not exists, Please Contact with school Admin.
          </span>
        ) : (
          ''
        )}
      </div>
      <form method='POST' name='customerData' action=''>
        <div className='row g-3'>
          <div className='col-md-8 mx-auto'>
            <div className='row g-3'>
              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='number'
                    className='form-control'
                    id='number'
                    placeholder='Enter yout GR Number'
                    onChange={(e) => onGrNoChange(e)}
                  />
                  <label htmlFor='text'>GR Number</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='number'
                    className='form-control'
                    id='number'
                    placeholder='Fees Amount'
                    readOnly
                    value={studentDetails ? studentDetails.fees??0 : 0}
                    //onChange={(e) => setFeesAmt(e.target.value)}
                  />
                  <label htmlFor='text'>Fees Amount</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='First Name'
                    readOnly
                    value={studentDetails ? studentDetails.first_name??'' : ''}
                  />
                  <label htmlFor='name'>First Name</label>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Middle Name'
                    readOnly
                    value={studentDetails ? studentDetails.middle_name??'' : ''}
                  />
                  <label htmlFor='text'>Middle Name</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Last Name'
                    readOnly
                    value={studentDetails ? studentDetails.last_name??'' : ''}
                  />
                  <label htmlFor='text'>Last Name</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    placeholder='Email Address'
                    readOnly
                    value={studentDetails ? studentDetails.email_id : ''}
                  />
                  <label htmlFor='text'>Email Address</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='number'
                    placeholder='Mobile Number'
                    readOnly
                    value={studentDetails ? studentDetails.mobile_no : ''}
                  />
                  <label htmlFor='text'>Mobile Number</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='number'
                    placeholder='standard'
                    readOnly
                    value={studentDetails ? studentDetails.standard : ''}
                  />
                  <label htmlFor='text'>standard</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='number'
                    placeholder='Division'
                    readOnly
                    value={studentDetails ? studentDetails.division : ''}
                  />
                  <label htmlFor='text'>Division</label>
                </div>
              </div>

              <div className='col-12'>
                <button
                  className='btn btn-primary w-100 py-3'
                  type='button'
                  value='Pay'
                  onClick={onPayClick}
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default RegularFees;
