import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchFacilityItemsFailure,
  fetchFacilityItemRequest,
  fetchFacilityItemsSuccess,
} from '../../redux/action/fetchaction';
import { getfacilities } from '../../redux/services/apicall';
import { getImagesByFolder } from '../../common/common';

const Facilities = () => {
  const dispatch = useDispatch();
  const { facilitycontent } = useSelector((state) => state.facilityreducer);
  const [facilityImages, setFacilitiesImage] = useState(null);

  useEffect(() => {
    getFacilityImages();
  }, []);

  const getFacilityImages = async () => {
    const imgFolder = { folder: 'images/facilities' };
    const imgs = await getImagesByFolder(imgFolder);
    setFacilitiesImage(imgs);
  };

  const getImageSrc = (id, imageName) => {
    let src = '';
    let img = imageName;
    if (!imageName?.startsWith(id + '_')) {
      img = id + '_' + imageName;
    }
    if (facilityImages && facilityImages.length > 0) {
      const images = facilityImages?.filter((a) => a.id == img);
      if (images && images.length > 0) {
        src = images[0].image;
      }
    }
    return src;
  };

  useEffect(() => {
    const fetchItems = async (data) => {
      dispatch(fetchFacilityItemRequest());
      try {
        //const data = await fetchoveriewFromApi()
        const response = await getfacilities();
        dispatch(fetchFacilityItemsSuccess(response.data));
        console.log(response.data);
      } catch (error) {
        dispatch(fetchFacilityItemsFailure(error.message));
      }
    };

    fetchItems();
  }, [dispatch]);

  return (
    <div class='container-xxl py-5'>
      <div class='container'>
        <div
          class='text-center wow fadeInUp '
          data-wow-delay='0.1s facilitiybutton'
        >
          <h6 class='section-title bg-white text-center text-primary px-3'>
            Our
          </h6>
          <h1 class='mb-5'>Facilities</h1>
        </div>

        <div class='row g-4 justify-content-center'>
          {facilitycontent &&
            facilitycontent.map((item, index) => (
              <div
                key={index}
                class='col-lg-12 col-md-6 wow fadeInUp titleanimation'
                data-wow-delay='0.1s'
              >
                <div class='course-item bg-light'>
                  <div className='row'>
                    <div class='col-lg-4 position-relative overflow-hidden'>
                      <img
                        class='img-fluid'
                        src={getImageSrc(item.id, item.image)}
                        alt=''
                      />
                    </div>

                    <div class='col-lg-8 text-left p-4 pb-0'>
                      <h5 class='mb-4'>{item.title}</h5>
                      <p> {item.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default Facilities;
