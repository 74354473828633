import React from 'react'
import $ from 'jquery'
import { getImages } from '../redux/services/apicall'
const imagesFolder = 'https://fees.sthelenas.in/';

export const importAll = (r) => {
  let images = {}
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item)
  })
  return images
}

export const GetImages = (folder, image) => {
  const imageItems = []
  const path = '../../assets/images/album'
  // const images = importAll(require.context(path, false, /\.(png|jpe?g|svg)$/))
  const images = importAll(folder)

  if (images && Object.entries(images) && Object.entries(images).length > 0) {
    for (const [key, value] of Object.entries(images)) {
      console.log(`${key}: ${value}`)
      imageItems.push({ image: value, id: key })
    }

    Object.entries(images).reduce((image, key) => {
      imageItems.push({ image: images[key], id: key })
    })
  }
  return imageItems
}

export const CheckImageSize = (file) => {
  const maxSize = 2 * 1024 * 1024 // 2MB in bytes

  if (file.size > maxSize) {
    $.notify('File size exceeds 2MB limit. Please choose a smaller file.', {
      className: 'error',
    })
    return false
  }
  return true
}


export const getImagesByFolder = async (folderObj) => {
  const images = await getImages(folderObj);
  console.log('data ', images);
  let items = [];
  images.forEach((image) => {
    if (image) {
      const imgPath = imagesFolder + folderObj.folder + '/' + image;
      items.push({ id: image, image: imgPath });
    }
  });
  return items;
};
