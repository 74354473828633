import { error } from 'jquery';
import {
  FITCH_CAREER_ITEM_REQUEST,
  FITCH_CAREER_ITEM_SUCCESS,
  FITCH_CAREER_ITEM_FAILURE,
} from '../constants/constants';

const initialState = {
  careerContent: [],
  loading: false,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FITCH_CAREER_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FITCH_CAREER_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        careerContent: action.payload,
      };
    case FITCH_CAREER_ITEM_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
