import Carousel from 'react-bootstrap/Carousel';
import CarouselImage from './CarouselImage';
import React, { useEffect, useRef, useState } from 'react';

import carousel1 from '../../assets/images/carousel-1.jpg'
import carousel2 from '../../assets/images/carousel-2.jpg'
import carousel3 from '../../assets/images/carousel-3.jpg'
import { GetImages } from '../common';




function Herobanner() {

    const [images, setImages] = useState([]);
    const imgRef = useRef(null);
    const [image, setImage] = useState(null);
    const imageFolder = 'Slider';

    useEffect(() => {
        const path = require.context('../../assets/images/Slider', false, /\.(png|jpe?g|svg)$/);
        const images = GetImages(path);
        setImages(images);
    }, []);

    return (
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
                {images && images.length > 0 && images.map((item, index) => {
                    if (item && item.image) {
                        return (<div key={index} className={index == 0 ? "carousel-item active" : "carousel-item"} >
                            <img src={item.image} className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>First slide label</h5>
                                <p>Some representative placeholder content for the first slide.</p>
                            </div>
                        </div>)
                    }
                })}


                {/* {/* <div className="carousel-item">
                      <img src={images && images.length>0 && images[3].image} className="d-block w-100" alt="..."/>
                      <div className="carousel-caption d-none d-md-block">
                          {/* <h5>First slide label</h5>
                          <p>Some representative placeholder content for the first slide.</p> 
            </div>
        </div> * /} */}



            </div >
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div >
    );
}

export default Herobanner;