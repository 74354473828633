import {
  FETCH_STUDENTINFO_ITEM_SUCCESS,
  FETCH_STUDENTINFO_ITEM_REQUEST,
  FETCH_STUDENTINFO_ITEM_FAILURE,
} from '../constants/constants';

const initialState = {
  studentInfo: [],
  loading: false,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STUDENTINFO_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_STUDENTINFO_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        studentInfo: action.payload,
      };
    case FETCH_STUDENTINFO_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        studentInfo: [],
      };

    default:
      return state;
  }
};

export default reducer;
