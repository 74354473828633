import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Homepage from '../../components/homepage/homepage';
import Aboutus from '../../components/aboutus/aboutus';
import Facilities from '../../components/facilities/facilities';
import Events from '../../components/events/Events';
import Contact from '../../components/contact/Contact';
import RegularFees from '../../components/fees/Regularfess';
import AdmissionForm from '../../components/fees/admissionform';
import Career from '../../components/career/Career';
import Thankyou from '../../components/fees/Thankyou';
import FeesRecipt from '../../components/fees/feesrecipt';

export class Header extends Component {
  render() {
    return (
      <div>
        <nav className='navbar navbar-expand-lg bg-dark navbar-light nav-white shadow sticky-top p-0'>
          <a
            href='index.html'
            className='navbar-brand d-flex align-items-center px-4 px-lg-5'
          >
            <h2 className='m-0 text-primary'>
              <i className='fa fa-cup me-3'></i>ST Helenas
            </h2>
          </a>
          <button
            type='button'
            className='navbar-toggler me-4'
            data-bs-toggle='collapse'
            data-bs-target='#navbarCollapse'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarCollapse'>
            <div className='navbar-nav ms-auto p-4 p-lg-0'>
              <a href='/' className='nav-item nav-link active'>
                Home
              </a>
              <a href='/aboutus' className='nav-item nav-link'>
                About
              </a>
              <a href='/facilities' className='nav-item nav-link'>
                Facilities
              </a>
              <a href='/Events' className='nav-item nav-link'>
                Events & Notice
              </a>
              <div className='nav-item dropdown'>
                <a
                  href='#'
                  className='nav-link dropdown-toggle'
                  data-bs-toggle='dropdown'
                >
                  Fees
                </a>
                <div className='dropdown-menu fade-down m-0'>
                  <a href='/RegularFees' className='dropdown-item'>
                    Regular Fees
                  </a>
                  <a href='/DownloadFeesRecipt' className='dropdown-item'>
                    Download Fees Receipt 
                  </a>
                  <a href='/admissionform' className='dropdown-item'>
                    New Admission Form
                  </a>
 
                </div>
              </div>
              <a href='/Career' className='nav-item nav-link'>
                Career
              </a>

              <a href='/contact' className='nav-item nav-link'>
                Contact
              </a>
            </div>
            <a
              target='_blank'
              href='./Stadmin/'
              className='btn btn-primary py-4 px-lg-5 d-none d-lg-block'
            >
              Staff Admin<i className='fa fa-arrow-right ms-3'></i>
            </a>
          </div>
        </nav>
        <Routes>
          <Route exact path='/' element={<Homepage />} />
          <Route path='/Aboutus' element={<Aboutus />} />
          <Route path='/facilities' element={<Facilities />} />
          <Route path='/Events' element={<Events />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/RegularFees' element={<RegularFees />} />
          <Route path='/admissionform' element={<AdmissionForm />} />
          <Route path='/career' element={<Career />} />
          <Route path='/Thankyou' element={<Thankyou />} />
          <Route path='/DownloadFeesRecipt' element={<FeesRecipt/>}/>
         </Routes>
      </div>
    );
  }
}

export default Header;
