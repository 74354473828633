import {legacy_createStore as createStore, combineReducers} from 'redux'
import slidderreducer  from './reducers/slidderreducer'
import overviewreducer from './reducers/overviewreducer'
import alumnireducer from './reducers/alumnireducer'
import aboutusreducer from './reducers/aboutusreducer'
import achivementreducer from './reducers/achivmentreducer'
import facilityreducer from './reducers/facilityreducer'
import galleryreducer from './reducers/galleryreducer'
import videoreducer from './reducers/videoreducer'
import notificationreducer from './reducers/notificationreducer'
import feesreducer  from './reducers/feesreducer'
import careerreducer from './reducers/careerreducer'
import addnewformreducer from './reducers/addnewformreducer'


const rootReducer = combineReducers({
   slidderreducer : slidderreducer,
   overviewreducer : overviewreducer,
   alumnireducer : alumnireducer,
   aboutusreducer : aboutusreducer,
   achivementreducer : achivementreducer,
   facilityreducer: facilityreducer,
   galleryreducer :galleryreducer,
   videoreducer :videoreducer,
   notificationreducer:notificationreducer,
   feesreducer: feesreducer,
   careerReducer: careerreducer,
   addnewformreducer: addnewformreducer
  })


const store = createStore(rootReducer);

export default store;