import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNotificationFailure,
  fetchNotificationRequest,
  fetchNotificationSuccess,
} from '../../redux/action/fetchaction';
import { getNotification } from '../../redux/services/apicall';
import Gallery from './Gallery';
import { StGallery } from './LightGallery';

const Events = () => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.notificationreducer);

  useEffect(() => {
    const fetchItems = async (data) => {
      dispatch(fetchNotificationRequest());
      try {
        const response = await getNotification();
        dispatch(fetchNotificationSuccess(response.data));
        console.log(data);
      } catch (error) {
        dispatch(fetchNotificationFailure(error.message));
      }
    };

    fetchItems();
  }, [dispatch]);

  const notifications = notification.map((item, index) => {
    if (item.Type === 'Regular') {
      return (
        <div className='card ' key={index}>
          <img src={"https://fees.sthelenas.in/images/notification/"+item.image} className='card-img-top' alt='...' />
          <div className='card-body'>
            <h4> {item.title} </h4>
          </div>
        </div>
      );
    }
  });

  return (
    <div className='container-xxl py-5'>
      <div class="text-center wow fadeInUp titleanimation wow fadeIn" data-wow-delay="0.1s">
    <h6 class="section-title bg-white text-center text-primary px-3">Events & Gallery</h6>
 </div>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-md-12'>
              
              {/* <div className='card '> */}
                 {/* <Gallery/> */}
                 <StGallery />
                {/* <div className='card-body'>
                  <h4> Title 1</h4>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className='col-md-4'>
        <div className="container-xxl py-5">

<div class="text-center wow fadeInUp titleanimation wow fadeIn" data-wow-delay="0.1s">
     <h6 class="mb-5">Notifications</h6>
</div>
</div>
          {notifications}
        </div>
      </div>
    </div>
  );
};
export default Events;
