import React, { useEffect, useRef, useState } from 'react';
import Services from '../../common/Services/Services';
import {
  fetchCareerItemRequest,
  fetchCareerItemsSuccess,
  fetchCareerItemsFailure,
} from '../../redux/action/fetchaction';

import { useDispatch, useSelector } from 'react-redux';
import { getCareers } from '../../redux/services/apicall';
import DisplayContnent from '../../common/Displaycontent/DisplayContnent';
import { GetImages } from '../../common/common';

const Career = () => {
  const dispatch = useDispatch();
  const { careerContent } = useSelector((state) => state.careerReducer);

  useEffect(() => {
    // const path = require.context(
    //   '../../assets/images/career',
    //   false,
    //   /\.(png|jpe?g|svg)$/
    // );
    // const images = GetImages(path);

    const careerImagesFolder = require.context(
      '../../../../Stadmin/src/assets/images/career',
      false,
      /\.(png|jpe?g|svg)$/
    );
    const careerImages = GetImages(careerImagesFolder);
  }, []);

  useEffect(() => {
    const fetchItems = async (data) => {
      dispatch(fetchCareerItemRequest());
      try {
        const data = await getCareers();
        dispatch(fetchCareerItemsSuccess(data));
        console.log(data);
      } catch (error) {
        dispatch(fetchCareerItemsFailure(error.message));
      }
    };

    fetchItems();
  }, [dispatch]);

  return (
    <div>
      <div className='container-xxl py-5 Hi'>
        <div
          class='text-center wow fadeInUp '
          data-wow-delay='0.1s facilitiybutton'
        >
          <h6 class='section-title bg-white text-center text-primary px-3'>
            Career
          </h6>
          <h1 class='mb-5'>Career with us</h1>
        </div>
        {careerContent &&
          careerContent.map((item, index) => (
            <div className='careerlist'>
              <h3> {item.title} </h3>
              <p>{item.content} </p>
              <div className='row'>
                <div className='postdate col-md-6'>
                  {' '}
                  <p>
                    Date of post :{' '}
                    {item.date ? new Date(item.date).toDateString() : null}{' '}
                  </p>{' '}
                </div>
                <div className='Apply col-md-6'>
                  {' '}
                  <p>Post on: career@sthelenas.in </p>{' '}
                </div>
              </div>
            </div>
          ))}
        : <></>
      </div>

      {/* {careerContent && careerContent.length > 0 ? (
        <DisplayContnent
          title={careerContent[0].title}
          content={careerContent[0].content}
          date={careerContent[0].date}
        />
      ) : (
        <></>
      )} */}
    </div>
  );
};
export default Career;
