import { ADD_NEW_FORM,ADD_NEW_FORM_SUCCESS,ADD_NEW_FORM_REQUEST,ADD_NEW_FORM_FAILURE,FITECH_NEW_FORM } from "../constants/constants";


const initialState = {
    newform: [],
    loading: false,
    error: '',

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_FORM_REQUEST:
            return {
              ...state,
              loading: true,
              error: null,
            }
        case FITECH_NEW_FORM:
          return {
            ...state,
            loading: true,
            error: null,
          }
        case ADD_NEW_FORM_SUCCESS:
          return {
            ...state,
            loading: true,
            newformcontent: action.payload,
          }
        case ADD_NEW_FORM_FAILURE:
          return {
            ...state,
            loading: true,
            error: action.payload,
          }
        case ADD_NEW_FORM:
          return {
            ...state,
            loading: true,
            newformcontent: [...state.newformcontent, action.payload],
          }
        // case UPDATE_VIDEO_CONTENT:
        //   const contents = state.videocontent.map((item) =>
        //     item.id === action.payload.id ? { ...item, content: action.payload.content,title: action.payload.title } : item,
        //   )
    
         
        default:
          return state
      }
}

export default reducer