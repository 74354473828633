import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewStudentImage,
  addStudentInformation,
  getStudentDetailsbyGrNo,
} from '../../redux/services/apicall';
import {
  fetchStudentInfoFailure,
  fetchStudentInfoRequest,
  fetchStudentInfoSuccess,
} from '../../redux/action/fetchaction';
import { NotificationManager } from 'react-notifications';
import { CheckImageSize } from '../../common/common';

const AdmissionForm = () => {
  const [grNo, setGerNo] = useState('');
  const [feesAmt, setFeesAmt] = useState(0);

  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fatherFirstName, setFatherFirstName] = useState('');
  const [fatherMiddleName, setFatherMiddleName] = useState('');
  const [fatherLastName, setFatherLastName] = useState('');
  const [motherFirstName, setMotherFirstName] = useState('');
  const [motherMiddleName, setMotherMiddleName] = useState('');
  const [motherLastName, setMotherLastName] = useState('');

  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [ofcTelNo1, setOfcTelNo1] = useState('');
  const [ofcTelNo2, setOfcTelNo2] = useState('');

  const [modeOfTransport, setModeOfTransport] = useState('');
  const [driverContactNo, setDriverContactNo] = useState('');
  const [sisterName, setSisterName] = useState('');
  const [sisterDivision, setSisterDivision] = useState('');
  const [medicalProblem, setMedicalProblem] = useState('');

  const [doctorNo, setDoctorNo] = useState('');
  const [childBloodGroup, setChildBloodGroup] = useState('');
  const [cast, setCast] = useState('');
  const [dateTime, setDateTime] = useState('');

  const dispatch = useDispatch();
  const { studentInfo } = useSelector((state) => state.feesreducer);
  const [selectedFile, setSelectedFile] = useState(null);

  const studentDetails =
    studentInfo && studentInfo.length > 0 ? studentInfo[0] : null;

  const [facilityImages, setFacilityImages] = useState([]);
  const imgRef = useRef(null);
  const [image, setImage] = useState(null);
  const imageFolder = 'facilities';
  const [images, setImages] = useState([]);

  const onGrNoChange = async (e) => {
    setGerNo(e.target.value);
    if (e.target.value && e.target.value.length >= 3) {
      dispatch(fetchStudentInfoRequest());
      try {
        const details = { grno: e.target.value };
        const studentDetails = await getStudentDetailsbyGrNo(details);
        dispatch(fetchStudentInfoSuccess(studentDetails?.data));
        console.log(studentDetails?.data);
      } catch (error) {
        dispatch(fetchStudentInfoFailure(error.message));
      }
    }
  };
  const handleFileChange = (event) => {
    console.log(event.target.files);
    if (!(event.target.files && CheckImageSize(event.target.files[0]))) {
      event.target.value = '';
      setImage('');
      setSelectedFile(null);
      return;
    }
    setSelectedFile(event.target.files);
    setImage(URL.createObjectURL(event.target.files[0]));
  };


  const submitForm = async () => {

    if(!firstName){
      NotificationManager.error("Error", "Please enter first name");
      return;
    }
    else if(!lastName){
      NotificationManager.error("Error", "Please enter last name");
      return;
    }
   else if(!address){
      NotificationManager.error("Error", "Please enter address");
      return;
    }
    else if(!contactNo){
      NotificationManager.error("Error", "Please enter contactNo");
      return;
    }
    else if(!modeOfTransport){
      NotificationManager.error("Error", "Please enter mode Of Transport");
      return;
    }
    let fileName = '';
    let file = selectedFile && selectedFile.length > 0 ? selectedFile[0] : null;
    if (file) {
      fileName = file.name;
    }

    const data = {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      father_first_name: fatherFirstName,
      father_middle_name: fatherMiddleName,
      father_last_name: fatherLastName,
      mother_first_name: motherFirstName,
      mother_middle_name: motherMiddleName,
      mother_last_name: motherLastName,
      address: address,
      email_id: email,
      contact_no: contactNo,
      ofc_tel_no1: ofcTelNo1,
      ofc_tel_no2: ofcTelNo2,
      mode_of_transport: modeOfTransport,
      driver_contact_no: driverContactNo,
      sister_name: sisterName,
      sister_division: sisterDivision,
      medical_problem: medicalProblem,
      doctor_no: doctorNo,
      child_blood_group: childBloodGroup,
      cast: cast,
      date_time: dateTime,
    };

    const response = await addStudentInformation(data).then(function (data) {
      if (file) {
        let imageObj = { image: file, name: fileName, itemid: data.id };
        addNewStudentImage(imageObj);
      } else {
        NotificationManager.success(
          'Success message',
          'Student Information Added Successfully!'
        );
      }
      setSelectedFile(null);
      //console.log('response', response);
    });
  };

  return (
    <div className='container-xxl py-5'>
      <div
        className='text-center wow fadeInUp '
        data-wow-delay='0.1s facilitiybutton'
      >
        <h6 className='section-title bg-white text-center text-primary px-3'>
          Student Information Form (to be filled by the Parent/Guardian).
        </h6>
        <h1 className='mb-5'>New Admission Form</h1>
      </div>

      <form method='POST' name='customerData' action='/ccavRequestHandler'>
        <div className='row g-3'>
          <div className='col-md-8 mx-auto'>
            <div className='row g-3'>
              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='First Name'
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <label htmlFor='name'>First Name</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Middle Name'
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                  <label htmlFor='text'>Middle Name</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Last Name'
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <label htmlFor='text'>Last Name</label>
                </div>
              </div>

              {/* Row 2 */}

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Fathers Name'
                    onChange={(e) => setFatherFirstName(e.target.value)}
                  />
                  <label htmlFor='name'>Father's Name</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Fathers Middle Name'
                    onChange={(e) => setFatherMiddleName(e.target.value)}
                  />
                  <label htmlFor='text'>Father's Middle Name</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Father Surname'
                    onChange={(e) => setFatherLastName(e.target.value)}
                  />
                  <label htmlFor='text'>Father's Surname</label>
                </div>
              </div>

              <div className='col-md-12'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Address'
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <label htmlFor='text'>Address</label>
                </div>
              </div>

              {/* Row 3 */}

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Mothers First Name'
                    onChange={(e) => setMotherFirstName(e.target.value)}
                  />
                  <label htmlFor='name'>Mother's Name</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Mothers Middle Name'
                    onChange={(e) => setMotherMiddleName(e.target.value)}
                  />
                  <label htmlFor='text'>Mother's Middle Name</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Mothers Surname'
                    onChange={(e) => setMotherLastName(e.target.value)}
                  />
                  <label htmlFor='text'>Mother's Surname</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    placeholder='Email Address'
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor='text'>Email Address</label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='number'
                    placeholder='Mobile Number'
                    onChange={(e) => setContactNo(e.target.value)}
                  />
                  <label htmlFor='text'>Mobile Number</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='number'
                    placeholder='standard'
                    //onChange={(e) => setStabd(e.target.value)}
                  />
                  <label htmlFor='text'>standard</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='number'
                    placeholder='Division'
                    //  onChange={(e) => setMotherMiddleName(e.target.value)}
                  />
                  <label htmlFor='text'>Division</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='file'
                    className='form-control'
                    id='number'
                    placeholder='Student photo'
                    onChange={handleFileChange}
                    ref={imgRef}
                    // onChange={(e) => setMotherMiddleName(e.target.value)}
                  />
                  <label htmlFor='text'>Student Photo</label>
                </div>
              </div>

              {/* Row 4 */}

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Office Phone Number'
                    onChange={(e) => setOfcTelNo1(e.target.value)}
                  />
                  <label htmlFor='name'>Office Phone Number</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Mode of transport To & From school'
                    onChange={(e) => setModeOfTransport(e.target.value)}
                  />
                  <label htmlFor='text'>
                    Mode of transport To & From school
                  </label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Name & contact number of the drive'
                    onChange={(e) => setDriverContactNo(e.target.value)}
                  />
                  <label htmlFor='text'>
                    Name & contact number of the driver.
                  </label>
                </div>
              </div>

              {/* Row 5 */}

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Any sibling in the school: If Yes'
                    //onChange={(e) => setMotherMiddleName(e.target.value)}
                  />
                  <label htmlFor='name'>
                    Any sibling in the school: If Yes
                  </label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Class'
                    //onChange={(e) => sets(e.target.value)}
                  />
                  <label htmlFor='text'>Class</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Div'
                    //onChange={(e) => setd(e.target.value)}
                  />
                  <label htmlFor='text'>Div</label>
                </div>
              </div>

              <div className='col-md-12'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Medical issue'
                    onChange={(e) => setMedicalProblem(e.target.value)}
                  />
                  <label htmlFor='text'>
                    Any particular Medical problem/ information that the school
                    should be aware of:
                  </label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Doctor'
                    onChange={(e) => setDoctorNo(e.target.value)}
                  />
                  <label htmlFor='text'>Doctor Name</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Doctor'
                    onChange={(e) => setDoctorNo(e.target.value)}
                  />
                  <label htmlFor='text'>Doctor Number</label>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-floating'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Child Blood Group'
                    onChange={(e) => setChildBloodGroup(e.target.value)}
                  />
                  <label htmlFor='text'>Child Blood Group</label>
                </div>
              </div>

              <div className='col-12'>
                <button
                  className='btn btn-primary w-100 py-3'
                  type='button'
                  value='submit'
                  onClick={submitForm}
                >
                  Submit Form
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AdmissionForm;
