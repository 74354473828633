import React, { Component } from 'react';
import Header from '../../common/Header/Header';
import Footer from '../../common/Footer/Footer';

class DefaultLayout extends Component {
  render() {
    return (
      <div className='Defaultlayout'>
        <Header />
        <Footer />
      </div>
    );
  }
}

export default DefaultLayout;
