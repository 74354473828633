export const FITCH_SLIIDER_ITEM_REQUEST = "FITCH_SLIIDER_ITEM_REQUEST"
export const FITCH_SLIIDER_ITEM_SUCCESS = "FITCH_SLIIDER_ITEM_SUCCESS"
export const FITCH_SLIIDER_ITEM_FAILURE = "FITCH_SLIIDER_ITEM_FAILURE"


export const FITCH_OVERVIEW_ITEM_SUCCESS = "FITCH_OVERVIEW_ITEM_SUCCESS"
export const FITCH_OVERVIEW_ITEM_REQUEST = "FITCH_OVERVIEW_ITEM_REQUEST"
export const FITCH_OVERVIEW_ITEM_FAILURE = "FITCH_OVERVIEW_ITEM_FAILURE" 
export const ADD_OVERVIEW_CONTENT = "ADD_OVERVIEW_CONTENT"
export const UPDATE_OVERVIEW_CONTENT ="UPDATE_OVERVIEW_CONTENT"
export const DELETE_OVERVIEW_CONTENT ="DELETE_OVERVIEW_CONTENT"

export const UploadImage = "UploadImage"

//----Alumni 

export const FITCH_ALUMNI_ITEM_SUCCESS = "FITCH_ALUMNI_ITEM_SUCCESS"
export const FITCH_ALUMNI_ITEM_REQUEST = "FITCH_ALUMNI_ITEM_REQUEST"
export const FITCH_ALUMNI_ITEM_FAILURE = "FITCH_ALUMNI_ITEM_FAILURE"
export const ADD_ALUMNI_CONTENT = "ADD_ALUMNI_CONTENT"
export const UPDATE_ALUMNI_CONTENT ="UPDATE_ALUMNI_CONTENT"
export const DELETE_ALUMNI_CONTENT ="DELETE_ALUMNI_CONTENT"

//----Aboutus

export const FITCH_ABOUTUS_ITEM_SUCCESS = "FITCH_ABOUTUS_ITEM_SUCCESS"
export const FITCH_ABOUTUS_ITEM_REQUEST = "FITCH_ABOUTUS_ITEM_REQUEST"
export const FITCH_ABOUTUS_ITEM_FAILURE = "FITCH_ABOUTUS_ITEM_FAILURE"
export const ADD_ABOUTUS_CONTENT = "ADD_ABOUTUS_CONTENT"
export const UPDATE_ABOUTUS_CONTENT ="UPDATE_ABOUTUS_CONTENT"
export const DELETE_ABOUTUS_CONTENT ="DELETE_ABOUTUS_CONTENT"


//---New Admission
export const FITECH_NEW_FORM = "FITECH_NEW_FORM"
export const ADD_NEW_FORM_SUCCESS = "ADD_NEW_FORM _SUCCESS"
export const ADD_NEW_FORM_REQUEST = "FADD_NEW_FORM _REQUEST"
export const ADD_NEW_FORM_FAILURE = "FADD_NEW_FORM _FAILURE"
export const ADD_NEW_FORM = "ADD_NEW_FORM"

//----Achivment

export const FITCH_ACHIVEMENT_ITEM_SUCCESS = "FITCH_ACHIVEMENT_ITEM_SUCCESS"
export const FITCH_ACHIVEMENT_ITEM_REQUEST = "FITCH_ACHIVEMENT_ITEM_REQUEST"
export const FITCH_ACHIVEMENT_ITEM_FAILURE = "FITCH_ACHIVEMENT_ITEM_FAILURE"
export const ADD_ACHIVEMENT_CONTENT = "ADD_ACHIVEMENT_CONTENT"
export const UPDATE_ACHIVEMENT_CONTENT ="UPDATE_ACHIVEMENT_CONTENT"
export const DELETE_ACHIVEMENT_CONTENT ="DELETE_ACHIVEMENT_CONTENT"

//---FACILITY
export const FITCH_FACILITY_ITEM_SUCCESS = "FITCH_FACILITY_ITEM_SUCCESS"
export const FITCH_FACILITY_ITEM_REQUEST = "FITCH_FACILITY_ITEM_REQUEST"
export const FITCH_FACILITY_ITEM_FAILURE = "FITCH_FACILITY_ITEM_FAILURE"
export const ADD_FACILITY_CONTENT = "ADD_FACILITY_CONTENT"
export const UPDATE_FACILITY_CONTENT ="UPDATE_FACILITY_CONTENT"
export const DELETE_FACILITY_CONTENT ="DELETE_FACILITY_CONTENT"

//--Gallery

export const FITCH_GALLERY_ITEM_SUCCESS = "FITCH_GALLERY_ITEM_SUCCESS"
export const FITCH_GALLERY_ITEM_REQUEST = "FITCH_GALLERY_ITEM_REQUEST"
export const FITCH_GALLERY_ITEM_FAILURE = "FITCH_GALLERY_ITEM_FAILURE"
export const ADD_GALLERY_CONTENT = "ADD_GALLERY_CONTENT"
export const UPDATE_GALLERY_CONTENT ="UPDATE_GALLERY_CONTENT"
export const DELETE_GALLERY_CONTENT ="DELETE_GALLERY_CONTENT"

//--Career

export const FITCH_CAREER_ITEM_REQUEST = "FITCH_CAREER_ITEM_REQUEST"
export const FITCH_CAREER_ITEM_SUCCESS = "FITCH_CAREER_ITEM_SUCCESS"
export const FITCH_CAREER_ITEM_FAILURE = "FITCH_CAREER_ITEM_FAILURE"
export const ADD_CAREER_CONTENT = "ADD_CAREER_CONTENT"
export const UPDATE_CAREER_CONTENT = "UPDATE_CAREER_CONTENT"
export const DELETE_CAREER_CONTENT = "DELETE_CAREER_CONTENT" 

//---VIDEO

export const FITCH_VIDEO_ITEM_SUCCESS = "FITCH_VIDEO_ITEM_SUCCESS"
export const FITCH_VIDEO_ITEM_REQUEST = "FITCH_VIDEO_ITEM_REQUEST"
export const FITCH_VIDEO_ITEM_FAILURE = "FITCH_VIDEO_ITEM_FAILURE"
export const ADD_VIDEO_CONTENT = "ADD_VIDEO_CONTENT"
export const UPDATE_VIDEO_CONTENT ="UPDATE_VIDEO_CONTENT"
export const DELETE_VIDEO_CONTENT ="DELETE_VIDEO_CONTENT"

//Notification

export const FITCH_NOTIFICATION_ITEM_SUCCESS = "FITCH_NOTIFICATION_ITEM_SUCCESS"
export const FITCH_NOTIFICATION_ITEM_REQUEST = "FITCH_NOTIFICATION_ITEM_REQUEST"
export const FITCH_NOTIFICATION_ITEM_FAILURE = "FITCH_NOTIFICATION_ITEM_FAILURE"

//Student info
export const FETCH_STUDENTINFO_ITEM_SUCCESS = "FITCH_NOTIFICATION_ITEM_SUCCESS"
export const FETCH_STUDENTINFO_ITEM_REQUEST = "FITCH_NOTIFICATION_ITEM_REQUEST"
export const FETCH_STUDENTINFO_ITEM_FAILURE = "FITCH_NOTIFICATION_ITEM_FAILURE"

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'

export const ActionTypes = {
    SET_PRODUCTS: "SET_PRODUCTS",
    SELECTED_PRODUCTS: "SELECTED_PRODUCTS",
    REMOVE_SELECTED_PRODUCTS: "REMOVE_SELECTED_PRODUCTS",
    FILTER_PRODUCTS: "FILTER_PRODUCTS",

}