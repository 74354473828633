import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import {
  fetchNotificationFailure,
  fetchNotificationRequest,
  fetchNotificationSuccess,
} from '../../redux/action/fetchaction';
import { getNotification } from '../../redux/services/apicall';
import { getImagesByFolder } from '../../common/common';

const Notifications = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [notificationImages, setNotificationImages] = useState([]);

  const handleClose = () => setShow(false);
  const { notification } = useSelector((state) => state.notificationreducer);
  let emergencyNotification = notification?.filter(
    (a) => a.Type === 'Emergency'
  );
  //?.reduce((prev, current) => (prev.id > current.id ? prev : current), {});

  emergencyNotification = emergencyNotification?.reduce(
    (prev, current) => (prev.id > current.id ? prev : current),
    {}
  );

  useEffect(() => {
    const fetchItems = async (data) => {
      dispatch(fetchNotificationRequest());
      try {
        const response = await getNotification();
        dispatch(fetchNotificationSuccess(response.data));
        console.log(data);
      } catch (error) {
        dispatch(fetchNotificationFailure(error.message));
      }
    };

    fetchItems();
  }, [dispatch]);

  useEffect(() => {
    setShow(true);
    getNotificationImages();
  }, []);

  const getNotificationImages = async () => {
    const imgFolder = { folder: 'images/notification' };
    const imgs = await getImagesByFolder(imgFolder);
    setNotificationImages(imgs);
  };

  const getImageSrc = (id, imageName) => {
    let src = '';
    let img = imageName;
    if (!imageName?.startsWith(id + '_')) {
      img = id + '_' + imageName;
    }
    if (notificationImages && notificationImages.length > 0) {
      const images = notificationImages?.filter((a) => a.id == img);
      if (images && images.length > 0) {
        src = images[0].image;
        return src;
      }
    }
  };
  return (
    <>
      {emergencyNotification && emergencyNotification?.title ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <div className='container '>
              <div
                className='text-center wow fadeInUp titleanimation wow fadeIn'
                data-wow-delay='0.1s'
              >
                <h6 className='section-title bg-white text-center text-primary px-3'>
                  Attention!
                </h6>
                <h5 className='mb-5'> {emergencyNotification.title}</h5>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <img
              class='img-fluid'
              src={getImageSrc(
                emergencyNotification.id,
                emergencyNotification.image
              )}
              alt=''
            />

            {emergencyNotification.content}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default Notifications;
