// api.js

import { NotificationManager } from 'react-notifications';
import axios from 'axios';

//const baseUrl = 'https://dev.vaibhavkhandelot.com';
const baseUrl = 'https://api.sthelenas.in'
// const baseUrl = 'http://localhost:8088';

const NewAdmissionFolder = 'newAdmissions';

const getData = async (url) => {
  const responce = await fetch(url);
  const data = await responce.json();
  return data;
};

const postData = async (url, data) => {
  const postData = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  const responce = await fetch(url, postData);
  const result = await responce.json();
  return result;
};

const fetchSlidderImagesFromAPI = async () => {
  const responce = await fetch(baseUrl + '/getSliderData');
  const data = await responce.json();
  console.log(data);
  return data;
};

const uploadImageFromApi = async (ImageUpload) => {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'multipart/form-data' },
  //   body: JSON.stringify(ImageUpload),
  // }
  // const result = await fetch(url, postData)
  // return result

  await fetch(baseUrl + '/slidder')
    .then((res) => res.json())
    .then((post) => {
      return post;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const uploadImageApi = async (formData) => {
  try {
    const response = await fetch(baseUrl + '/uploadimage', {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    console.log('Image uploaded:', data);
    return data;
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};

const uploadFile = (url, formData, message) => {
  fetch(url, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      console.log(res);
      NotificationManager.success('Success message', message);
      return res;
    })
    .catch((err) => {
      console.log('Error occured', err);
      NotificationManager.error('error message', 'Record updated failed');
      return false;
    });
};

// overview

const fetchoveriewFromApi = async () => {
  const responce = await fetch(baseUrl + '/overview');
  const data = await responce.json();
  console.log(data);
  return data;
};

const getOverviews = async () => {
  return await getData(baseUrl + '/getoverviews');
};

const addOverviewContent = async (formData) => {
  const updateOverviewUrl = baseUrl + '/createoverview';
  return await postData(updateOverviewUrl, formData);
};

const updateOverviewContent = async (formData) => {
  const updateOverviewUrl = baseUrl + '/updateoverview';
  return await postData(updateOverviewUrl, formData);
};

const deleteOVerviewContent = async (data) => {
  const url = baseUrl + '/deleteoverview';
  return await postData(url, data);
};

// Alumni

const fetchalumniFromApi = async () => {
  const responce = await fetch(baseUrl + '/alumini');
  const data = await responce.json();
  console.log(data);
  return data;
};

const getAlumni = async () => {
  return await getData(baseUrl + '/getalumini');
};

const addAlumniImage = (data) => {
  const updateAluminiUrl = baseUrl + '/uploadAluminiImage';
  const formData = new FormData();
  formData.append('image', data.image);
  formData.append('name', data.name);
  formData.append('content', data.information);

  fetch(updateAluminiUrl, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log('Error occured', err);
    });
};

const addAlumniContent = async (data) => {
  const updateAluminiUrl = baseUrl + '/createalumini';
  return await postData(updateAluminiUrl, data);
};

const updateAlumniContent = async (formData) => {
  const updateAluminiUrl = baseUrl + '/updatealumini';
  return await postData(updateAluminiUrl, formData);
};

const deleteAluminiContent = async (data) => {
  const url = baseUrl + '/deletealumini';
  return await postData(url, data);
};

// aboutus

const addAboutusImage = (data) => {
  const updateAluminiUrl = baseUrl + '/uploadAluminiImage';
  const formData = new FormData();
  formData.append('image', data.image);
  formData.append('name', data.name);
  formData.append('content', data.information);

  fetch(updateAluminiUrl, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log('Error occured', err);
    });
};

const getAboutus = async () => {
  return await getData(baseUrl + '/getaboutus');
};

const addAboutusContent = async (formData) => {
  const updateAluminiUrl = baseUrl + '/createaboutus';
  return await postData(updateAluminiUrl, formData);
};

const updateAboutusContent = async (formData) => {
  const updateAluminiUrl = baseUrl + '/updateaboutus';
  return await postData(updateAluminiUrl, formData);
};

const deleteAboutusContent = async (data) => {
  const url = baseUrl + '/deleteaboutus';
  return await postData(url, data);
};

// achivement

const fetchachivementFromApi = async () => {
  const responce = await fetch(baseUrl + '/achivement');
  const data = await responce.json();
  console.log(data);
  return data;
};

const addAchivementImage = (data) => {
  const achivemtimage = baseUrl + '/uploadAchivementImage';
  const formData = new FormData();
  formData.append('image', data.image);

  fetch(achivemtimage, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log('Error occured', err);
    });
};

const getachivement = async () => {
  return await getData(baseUrl + '/getachievements');
};

const addAchivementContent = async (formData) => {
  const updateAluminiUrl = baseUrl + '/createachievement';
  return await postData(updateAluminiUrl, formData);
};

const updateAchivementContent = async (formData) => {
  const updateAluminiUrl = baseUrl + '/updateachievement';
  return await postData(updateAluminiUrl, formData);
};

const deleteAchivementContent = async (data) => {
  const url = baseUrl + '/deleteachivement';
  return await postData(url, data);
};

//--facility

const fetchfacilityFromApi = async () => {
  const responce = await fetch(baseUrl + '/facilities');
  const data = await responce.json();
  console.log(data);
  return data;
};

const addFacilityImage = (data) => {
  const facilityimage = baseUrl + '/uploadFacilityImage';
  const formData = new FormData();
  formData.append('image', data.image);

  fetch(facilityimage, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log('Error occured', err);
    });
};

const getfacilities = async () => {
  return await getData(baseUrl + '/getfacilities');
};

const addFacilityContent = async (formData) => {
  const updateFacilityUrl = baseUrl + '/createfacility';
  return await postData(updateFacilityUrl, formData);
};

const updateFacilityContent = async (formData) => {
  const updateFacilityUrl = baseUrl + '/updatefacility';
  return await postData(updateFacilityUrl, formData);
};

const deleteFacilityContent = async (data) => {
  const url = baseUrl + '/deletefacility';
  return await postData(url, data);
};

//--Gallery

const fetchgalleryFromApi = async () => {
  const responce = await fetch(baseUrl + '/albums');
  const data = await responce.json();
  console.log(data);
  return data;
};

const addGalleryImage = (data) => {
  const galleryimage = baseUrl + '/uploadGalleryImage';
  const formData = new FormData();
  formData.append('image', data.image);

  fetch(galleryimage, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log('Error occured', err);
    });
};

const getgallery = async () => {
  return await getData(baseUrl + '/getgalleryitems');
};

const addGalleryContent = async (formData) => {
  const updateGalleryiUrl = baseUrl + '/creategalleryitem';
  return await postData(updateGalleryiUrl, formData);
};

const updateGalleryContent = async (formData) => {
  const updateGalleryiUrl = baseUrl + '/updategalleryitem';
  return await postData(updateGalleryiUrl, formData);
};

const deleteGalleryContent = async (data) => {
  const url = baseUrl + '/deleteGalleryItem';
  return await postData(url, data);
};

//--Video

const fetchvideoFromApi = async () => {
  const responce = await fetch(baseUrl + '/album');
  const data = await responce.json();
  console.log(data);
  return data;
};

const addVideoImage = (data) => {
  const videoimage = baseUrl + '/uploadVideoImage';
  const formData = new FormData();
  formData.append('image', data.image);

  fetch(videoimage, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log('Error occured', err);
    });
};

const getvideo = async () => {
  return await getData(baseUrl + '/getalbums');
};

const addVideoContent = async (formData) => {
  const updateVideoiUrl = baseUrl + '/createvideo';
  return await postData(updateVideoiUrl, formData);
};

const updateVideoContent = async (formData) => {
  const updateVideoiUrl = baseUrl + '/updatevideo';
  return await postData(updateVideoiUrl, formData);
};

const deleteVideoContent = async (data) => {
  const url = baseUrl + '/deleteVideo';
  return await postData(url, data);
};

const getNotification = async () => {
  return await getData(baseUrl + '/getNotification');
};

const getCareers = async () => {
  return await getData(baseUrl + '/getcareers');
};

const openCcavenuepage = async (data) => {
  const url = baseUrl + '/payment/initiate';
  return await postData(url, data);
  //return await getData(baseUrl + '/about');
};

//*************** */

const getStudentDetailsbyGrNo = async (formData) => {
  const getStudentInfourl = baseUrl + '/getstudentdetailsbygrno';
  return await postData(getStudentInfourl, formData);
};

const getImages = async (formData) => {
  const imagesUrl = baseUrl + '/getimages';
  return await postData(imagesUrl, formData);
};

const addStudentInformation = async (data) => {
  const url = baseUrl + '/addStudentInformation';
  return await postData(url, data);
};

const payStudentFee = async (data) => {
  const url = baseUrl + '/paystudentfee';
  return await postData(url, data);
};

const getPaidStudentfees = async (data) => {
  const url = baseUrl + '/getpaidstudentfees';
  return await postData(url, data);
};

const addNewStudentImage = (data) => {
  const formData = new FormData();
  formData.append('image', data.image);
  formData.append('itemid', data.itemid);
  const url = baseUrl + `/uploadImage/${NewAdmissionFolder}/${data.itemid}`;

  return uploadFile(url, formData, 'Record updated Successfully!');
};

const getReceipt = async (data) => {
  const url = baseUrl + '/createreceipt';
  return await postData(url, data);
};

const downloadReceipt = (grno) => {
  window.open(baseUrl + '/downloadreceipt/' + grno);
};

export {
  //Facility
  fetchfacilityFromApi,
  getfacilities,
  addFacilityContent,
  updateFacilityContent,
  deleteFacilityContent,
  addFacilityImage,

  //Gallery
  fetchgalleryFromApi,
  getgallery,
  addGalleryContent,
  updateGalleryContent,
  deleteGalleryContent,
  addGalleryImage,

  //Achivment
  fetchachivementFromApi,
  getachivement,
  addAchivementContent,
  updateAchivementContent,
  deleteAchivementContent,

  //Aboutus
  getAboutus,
  addAboutusContent,
  updateAboutusContent,
  deleteAboutusContent,
  fetchSlidderImagesFromAPI,
  uploadImageFromApi,

  //Overview
  fetchoveriewFromApi,
  uploadImageApi,
  getOverviews,
  addOverviewContent,
  updateOverviewContent,
  deleteOVerviewContent,

  //Alumini
  getAlumni,
  addAlumniContent,
  updateAlumniContent,
  deleteAluminiContent,
  addAlumniImage,
  addAboutusImage,
  addAchivementImage,
  fetchalumniFromApi,

  //video
  getvideo,
  addVideoContent,
  updateVideoContent,
  deleteVideoContent,
  addVideoImage,
  fetchvideoFromApi,
  getNotification,
  getStudentDetailsbyGrNo,
  openCcavenuepage,
  getCareers,
  getImages,
  addStudentInformation,
  payStudentFee,
  getPaidStudentfees,
  addNewStudentImage,
  downloadReceipt,
  getReceipt
};
