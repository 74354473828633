import React, { useEffect, useState } from 'react';
import {
  downloadReceipt,
  getReceipt,
} from '../../redux/services/apicall';

const Thankyou = () => {
  const [params, setParams] = useState({});

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const paramsObject = {};
    urlParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    setParams(paramsObject);
  }, []);

  const handleDownload = async () => {
    try {
      const data = {
        grno: params.grno,
      };
      await getReceipt(data)
        .then(function (response) {
          if (response.success) {
            downloadReceipt(params.grno);
          }
        })
        .catch(function (e) {
          console.error('Error creating receipt', e);
        });
    } catch (error) {
      console.error('Error creating receipt', error);
    }
  };

  return (
    <div className='container py-5' style={{ height: '350px' }}>
      <div
        className='text-center wow fadeInUp '
        data-wow-delay='0.1s facilitiybutton'
      >
        <h6 className='section-title bg-white text-center text-primary px-3'>
          Thankyou
        </h6>

        <div className='col-md-8 mx-auto'>
          <h4 className='mb-5'>
            {' '}
            {params.T == 'S'
              ? 'Thank you your Payment, Transtion is completed'
              : params.T == 'F'
              ? 'Something went wrong'
              : params.T == 'CT'
              ? 'Your Transtion is cancelled'
              : 'Something went wrong'}
          </h4>
          <p>
            {/* {' '}
            <a href='#' onClick={() => handleDownload()}>
              Download your Fees Recipt{' '}
            </a>{' '} */}

            <input
              type='button'
              value={'Download your Fees Recipt'}
              onClick={handleDownload}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
